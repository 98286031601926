.am-switch {
  display: inline-block;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  cursor: pointer;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}
.am-switch .checkbox {
  width: 51px;
  height: 31px;
  border-radius: 31px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #e5e5e5;
  z-index: 0;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
.am-switch .checkbox:before {
  content: ' ';
  position: absolute;
  left: 2px;
  top: 2px;
  width: 47px;
  height: 27px;
  border-radius: 27px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  z-index: 1;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
.am-switch .checkbox:after {
  content: ' ';
  width: 27px;
  height: 27px;
  border-radius: 27px;
  background: #fff;
  position: absolute;
  z-index: 2;
  top: 2px;
  left: 2px;
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all 200ms;
  transition: all 200ms;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 2px 11.5px 0 rgba(0, 0, 0, 0.08), -1px 2px 2px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 2px 11.5px 0 rgba(0, 0, 0, 0.08), -1px 2px 2px 0 rgba(0, 0, 0, 0.1);
}
.am-switch .checkbox.checkbox-disabled {
  z-index: 3;
}
.am-switch input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 0 none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.am-switch input[type="checkbox"]:checked + .checkbox {
  background: #4dd865;
}
.am-switch input[type="checkbox"]:checked + .checkbox:before {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
}
.am-switch input[type="checkbox"]:checked + .checkbox:after {
  -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
          transform: translateX(20px);
}
.am-switch input[type="checkbox"]:disabled + .checkbox {
  opacity: 0.3;
}
.am-switch.am-switch-android .checkbox {
  width: 72px;
  height: 23px;
  border-radius: 3px;
  background: #a7aaa6;
}
.am-switch.am-switch-android .checkbox:before {
  display: none;
}
.am-switch.am-switch-android .checkbox:after {
  width: 35px;
  height: 21px;
  border-radius: 2px;
  -webkit-box-shadow: none;
          box-shadow: none;
  left: 1PX;
  top: 1PX;
}
.am-switch.am-switch-android input[type="checkbox"]:checked + .checkbox {
  background: #108ee9;
}
.am-switch.am-switch-android input[type="checkbox"]:checked + .checkbox:before {
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
}
.am-switch.am-switch-android input[type="checkbox"]:checked + .checkbox:after {
  -webkit-transform: translateX(35px);
      -ms-transform: translateX(35px);
          transform: translateX(35px);
}
